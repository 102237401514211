const initialState = {
    isLoading: false,
    loaderColor: '#1976d2',
    user: {
        loading: true,
        loadingText: 'Please wait, loading...',
        isLoggedIn: false,
        loginValidate: {
            error: false,
            message: ''
        },
        loginForm: {
            device_type: 'Web',
            tour_id: 0,
            primary_id: 0,
            auth_pin: 0,
            rememberMe: false,
        },
        user: {},
        users: [],
    }
};
export default initialState;