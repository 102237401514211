import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import './Styles.css';
import LiveBidding from "./LiveBidding";
import CreateSellNFT from './CreateSellNFT';
import NewestItems from './NewestItems';
import TopSeller from './TopSeller';
import ExploreProduct from './ExploreProduct';
import TopCollection from './TopCollection';

const Home = (props) => {
    /*** Render output ***/
    return (
        <HelmetProvider>
            <Helmet>
                <title>Home Default || XOXO - NFT Marketplace Template</title>
            </Helmet>
            <div className="slider-one rn-section-gapTop">
                <div className="container">
                    <div className="row row-reverce-sm align-items-center">
                        <div className="col-lg-5 col-md-6 col-sm-12 mt_sm--50">
                            <h2 className="title" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">Discover Digital Art, Collect and Sell Your Favourite NFTs.</h2>
                            <p className="slide-disc" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">Partner with one of the International finest marketplace to showcase your brand and
                                products.</p>
                            <div className="button-group">
                                <a className="btn btn-large btn-primary" href="#" data-sal-delay="400" data-sal="slide-up" data-sal-duration="800">Get Started</a>
                                <a className="btn btn-large btn-primary-alta" href="creator.html" data-sal-delay="500" data-sal="slide-up" data-sal-duration="800">Create</a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 offset-lg-2">
                            <div className="slider-thumbnail">
                                <img src="assets/images/slider/slider-1.gif" className="rounded-4" alt="Slider Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LiveBidding />
            <CreateSellNFT />
            <NewestItems />
            <TopSeller />
            <ExploreProduct />
            <TopCollection />
        </HelmetProvider>
    );
}

export default Home;