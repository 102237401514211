import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
/*** Imports Components ***/
import Template from "./Template/Template";
import PageNotFound from "./PageNotFound";
import HomeContainers from '../containers/HomeContainers';
import WalletContainers from '../containers/WalletContainers';
import ExploreContainers from "../containers/ExploreContainers";

const AppRoutes = (props) => {
    return (
        <BrowserRouter {...props}>
            <Routes>
                <Route path="/" element={<Template />}>
                    <Route exact path="/" element={<HomeContainers />} />
                    <Route exact path="/connect" element={<WalletContainers />} />
                    <Route exact path="/explore" element={<ExploreContainers />} />
                </Route>
                {/* <Route path="/tournament" element={<TournamentContainers />} />
                <Route path="/users/:tourId" element={<UserContainers />} />
                <Route path="/login" element={<LoginContainers />} />
                <Route exact path="/" element={<PrivateRoute />}>
                    <Route path="/courts" element={<CourtContainers />} />
                    <Route path="/match-list/:courtId" element={<MatchLIstContainers />} />
                    <Route path="/match-score" element={<MatchScoreContainers />} />
                </Route> */}
                <Route element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;