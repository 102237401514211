import React from "react";

const Footer = () => {
    return (
        <>
            <div className="rn-footer-one rn-section-gap bg-color--1 mt--100 mt_md--80 mt_sm--80">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="widget-content-wrapper">
                                <div className="footer-left">
                                    <div className="logo-thumbnail logo-custom-css">
                                        <a className="logo-light" href="index.html"><img src="assets/images/logo/logo-white.png" alt="nft-logo" /></a>
                                        <a className="logo-dark" href="index.html"><img src="assets/images/logo/logo-dark.png" alt="nft-logo" /></a>
                                    </div>
                                    <p className="rn-footer-describe">
                                        A dedicated NFT marketplace for the Artists, Musicians, Celebrities and for all  aspirant collectors and creators around the world.
                                    </p>
                                </div>
                                <div className="widget-bottom mt--40 pt--40">
                                    <h6 className="title">Get The Latest XOXO Updates </h6>
                                    <div className="input-group">
                                        <input type="text" className="form-control bg-color--2" placeholder="Your username" aria-label="Recipient's username" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary-alta btn-outline-secondary" type="button">Subscribe</button>
                                        </div>
                                    </div>
                                    <div className="newsletter-dsc">
                                        <p>Email is safe. We don't spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_mobile--40">
                            <div className="footer-widget widget-quicklink">
                                <h6 className="widget-title">XOXO</h6>
                                <ul className="footer-list-one">
                                    <li className="single-list"><a href="#">Arts</a></li>
                                    <li className="single-list"><a href="#">Musics</a></li>
                                    <li className="single-list"><a href="#">Collectibles</a></li>
                                    <li className="single-list"><a href="#">Visual Design</a></li>
                                    <li className="single-list"><a href="#">Virtual Worlds</a></li>
                                    <li className="single-list"><a href="#">Photography</a></li>
                                    <li className="single-list"><a href="#">Events</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                            <div className="footer-widget widget-information">
                                <h6 className="widget-title">Resources</h6>
                                <ul className="footer-list-one">
                                    <li className="single-list"><a href="about.html">About Us</a></li>
                                    <li className="single-list"><a href="blog.html">FAQs</a></li>
                                    <li className="single-list"><a href="#">Help Centre</a></li>
                                    <li className="single-list"><a href="#">Creators</a></li>
                                    <li className="single-list"><a href="#">Collectors</a></li>
                                    <li className="single-list"><a href="#">Newsletter</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                            <div className="footer-widget">
                                <h6 className="widget-title">Recent Sold Out</h6>
                                <ul className="footer-recent-post">
                                    <li className="recent-post">
                                        <div className="thumbnail">
                                            <a href="product-details.html">
                                                <img src="assets/images/portfolio/portfolio-01.jpg" alt="Product Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="product-details.html">#21 The Wonder</a></h6>
                                            <p>Highest bid 1/20</p>
                                            <span className="price">0.244wETH</span>
                                        </div>
                                    </li>
                                    <li className="recent-post">
                                        <div className="thumbnail">
                                            <a href="product-details.html">
                                                <img src="assets/images/portfolio/portfolio-02.jpg" alt="Product Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="product-details.html">Diamond Dog</a></h6>
                                            <p>Highest bid 1/20</p>
                                            <span className="price">0.022wETH</span>
                                        </div>
                                    </li>
                                    <li className="recent-post">
                                        <div className="thumbnail">
                                            <a href="product-details.html">
                                                <img src="assets/images/portfolio/portfolio-03.jpg" alt="Product Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="product-details.html">Morgan11</a></h6>
                                            <p>Highest bid 1/20</p>
                                            <span className="price">0.892wETH</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-one ptb--20 bg-color--1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="copyright-left">
                                <span>©2022 TransforMETArix, Inc. All rights reserved.</span>
                                <ul className="privacy">
                                    <li><a href="terms-condition.html">Terms</a></li>
                                    <li><a href="privacy-policy.html">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="copyright-right">
                                <ul className="social-copyright">
                                    <li><a href="#"><i data-feather="facebook"></i></a></li>
                                    <li><a href="#"><i data-feather="twitter"></i></a></li>
                                    <li><a href="#"><i data-feather="instagram"></i></a></li>
                                    <li><a href="#"><i data-feather="linkedin"></i></a></li>
                                    <li><a href="#"><i data-feather="mail"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mouse-cursor cursor-outer"></div>
            <div className="mouse-cursor cursor-inner"></div>
            <div className="rn-progress-parent">
                <svg className="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </>
    );
}
export default Footer;