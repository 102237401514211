import React from "react";

const Preloader = () => {
    return (
        <div className="preloader">
            <div className='loader'>
                <div className='circle'></div>
                <div className='circle'></div>
                <div className='circle'></div>
                <div className='circle'></div>
                <div className='circle'></div>
            </div>
        </div>
    );
}
export default Preloader;