import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Connect = (props) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Connect || XOXO - NFT Marketplace Template</title>
                <body className="template-color-1 nft-body-connect" />
                <script src="assets/js/vendor/nft.js"></script>
            </Helmet>
            <div className="rn-breadcrumb-inner ptb--30">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-12">
                            <h5 className="title text-center text-md-start">Make your payment easyer</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <ul className="breadcrumb-list">
                                <li className="item"><a href="/">Home</a></li>
                                <li className="separator"><i className="feather-chevron-right"></i></li>
                                <li className="item current">Make your payment easyer</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rn-connect-area rn-section-gapTop">
                <div className="container">
                    <div className="row g mb--50 mb_md--30 mb_sm--30 align-items-center">
                        <div className="col-lg-6" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                            <h3 className="connect-title">Connect your wallet</h3>
                            <p className="connect-td">Connect with one of available wallet providers or create a new wallet. <a href="#">What is a wallet?</a></p>
                        </div>
                        <div className="col-lg-6" data-sal="slide-up" data-sal-delay="200" data-sal-duration="800">
                            <p className="wallet-bootm-disc">
                                We do not own your private keys and cannot access your funds without your confirmation.
                            </p>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6" data-sal="slide-up" data-sal-delay="150" data-sal-duration="500">
                            <div className="connect-thumbnail">
                                <div className="left-image">
                                    <img src="assets/images/connect/connect-01.jpg" alt="Nft_Profile" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-5">
                                <div className="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                                    <div className="wallet-wrapper">
                                        <div className="inner">
                                            <div className="icon">
                                                <i data-feather="cast"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href="#">Bitcollet</a></h4>
                                                <p className="description">I throw myself down among the tall.</p>
                                            </div>
                                        </div>
                                        <a className="over-link" href="#"></a>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="200" data-sal-duration="800">
                                    <div className="wallet-wrapper">
                                        <div className="inner">
                                            <div className="icon">
                                                <i className="color-purple" data-feather="box"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href="#">GrasCash</a></h4>
                                                <p className="description">This is a great deals For cash transfer</p>
                                            </div>
                                        </div>
                                        <a className="over-link" href="#"></a>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="250" data-sal-duration="800">
                                    <div className="wallet-wrapper">
                                        <div className="inner">
                                            <div className="icon">
                                                <i className="color-pink" data-feather="award"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href="#">Import</a></h4>
                                                <p className="description">Great oppertunity to reach them.</p>
                                            </div>
                                        </div>
                                        <a className="over-link" href="#"></a>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="300" data-sal-duration="800">
                                    <div className="wallet-wrapper">
                                        <div className="inner">
                                            <div className="icon">
                                                <i className="color-yellow" data-feather="briefcase"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href="#">TiOne</a></h4>
                                                <p className="description">Built your bigger offers then me</p>
                                            </div>
                                        </div>
                                        <a className="over-link" href="#"></a>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="350" data-sal-duration="800">
                                    <div className="wallet-wrapper">
                                        <div className="inner">
                                            <div className="icon">
                                                <i className="color-green" data-feather="command"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href="#">Bkashes</a></h4>
                                                <p className="description">Cash Transfer for easyest way you wast</p>
                                            </div>
                                        </div>
                                        <a className="over-link" href="#"></a>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-6 col-md-4 col-12 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="400" data-sal-duration="800">
                                    <div className="wallet-wrapper">
                                        <div className="inner">
                                            <div className="icon">
                                                <i className="color-blue" data-feather="cpu"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href="#">Pyynle</a></h4>
                                                <p className="description">More then myself down among the Cash.</p>
                                            </div>
                                        </div>
                                        <a className="over-link" href="#"></a>
                                    </div>
                                </div>
                                <div className="col-12" data-sal="slide-up" data-sal-delay="450" data-sal-duration="800">
                                    <div className="wallet-wrapper">
                                        <div className="inner">
                                            <div className="icon">
                                                <i className="color-red" data-feather="gitlab"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href="#">YesCash</a></h4>
                                                <p className="description">Biggest Bank transfer for best oppertunity</p>
                                            </div>
                                        </div>
                                        <a className="over-link" href="#"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
}

export default Connect;