import React from "react";
import { Outlet } from "react-router-dom";
import Preloader from './Preloader';
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import Footer from "./Footer";

const Template = () => {
    return (
        <>
            <Preloader />
            <Header />
            <MobileMenu />
            <Outlet />
            <Footer />
        </>
    );
}
export default Template;